import React, { useEffect, useState } from "react";
import Main from "../../_app/layouts/Main";
import { featureFlagsMap } from "../../feature/utils";
import { permissionCodes } from "../../permission/hooks";
import makeStyles from "@mui/styles/makeStyles";
import { Theme } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import { SelectAction } from "../components/UsageAlerts/SelectAction";
import { SelectCriteria } from "../components/UsageAlerts/SelectCriteria";
import { BillingAlertBulkEdit, UsageAlert } from "../types";
import { useHistory, useLocation } from "react-router-dom";
import { UIButton } from "../../_app/components";
import DeleteSweepIcon from "@mui/icons-material/DeleteSweep";
import { Box } from "../../_app/components/UIBox";
import BulkEditConfirmationModal from "../components/UsageAlerts/BulkEditConfirmationModal";
import { useBulkEditAlerts } from "../hooks";

const BulkEditUsageAlerts = () => {
  const classes = useStyles();
  const { state } = useLocation<{ selectedAlerts: string[] }>();
  const history = useHistory();
  const [usageAlertConfig, setUsageAlertConfig] = useState({
    product: "MOBILE",
    level: "CLI",
  } as UsageAlert);
  const [validationErrors, setValidationErrors] = useState({});
  const [showConfirm, setShowConfirm] = useState(false);
  const { mutate: updateBulk, isLoading } = useBulkEditAlerts();

  useEffect(() => {
    // Redirect to usage alerts page if no alerts are selected or passed in history state
    if (!state?.selectedAlerts || state.selectedAlerts.length === 0) {
      handleBack();
    }
  }, [state]);

  const title = "Bulk Edit Usage Alerts";
  const subtitle =
    state?.selectedAlerts?.length === 1 ? "1 usage alert selected" : `${state?.selectedAlerts?.length} usage alerts selected`;

  const handleBack = () => {
    history.push("/usage-alerts");
  };

  const discardChanges = () => {
    setUsageAlertConfig({
      product: "MOBILE",
      level: "CLI",
    } as UsageAlert);
  };

  const hasChanges = () => {
    return usageAlertConfig.criteria || usageAlertConfig.alertAction || usageAlertConfig.name;
  };

  const handleSubmit = () => {
    const billingAlertBulkEdit: BillingAlertBulkEdit = {
      alertIds: state.selectedAlerts,
      name: usageAlertConfig?.name,
      criterion: usageAlertConfig.criteria?.[0],
      contacts: usageAlertConfig?.alertAction?.contacts,
      notifyAssetsBySMS: usageAlertConfig?.alertAction?.notifyAssetsBySMS,
      bar: usageAlertConfig?.alertAction?.bar,
    };

    updateBulk(billingAlertBulkEdit);

    setShowConfirm(false);
  };

  const hasError = Object.values(validationErrors).some((value) => value !== undefined);
  const isWithoutNotification =
    usageAlertConfig?.alertAction?.notifyAssetsBySMS === false && usageAlertConfig?.alertAction?.contacts?.length === 0;
  const discardChangesDisabled = !hasChanges();
  const submitDisabled = hasError || !hasChanges() || isWithoutNotification;

  return (
    <Main
      title={title}
      subtitle={subtitle}
      data-cy="add-edit-usage-alert-page"
      featureFlag={featureFlagsMap.BULK_EDIT_USAGE_ALERTS}
      accessPermission={permissionCodes.ALERTS_ACCESS_FEATURE}
      needSelectedAccount={true}
    >
      <div className={classes.contentCtr}>
        {
          <>
            <SelectCriteria
              isBulkEdit
              usageAlert={usageAlertConfig}
              setAlert={setUsageAlertConfig}
              setErrors={setValidationErrors}
            ></SelectCriteria>
            <SelectAction usageAlert={usageAlertConfig} setAlert={setUsageAlertConfig} bulkEdit></SelectAction>
            <Box className={classes.buttonContainer}>
              <UIButton variant="text" onClick={handleBack} data-cy="back">
                Back
              </UIButton>
              <Box className={classes.buttonGroup}>
                <UIButton
                  disabled={discardChangesDisabled}
                  variant="outlined"
                  startIcon={<DeleteSweepIcon />}
                  onClick={discardChanges}
                  data-cy="discard-changes"
                >
                  Discard Changes
                </UIButton>
                <UIButton
                  disabled={submitDisabled}
                  variant="contained"
                  onClick={() => {
                    setShowConfirm(true);
                  }}
                  data-cy="submit"
                >
                  Submit
                </UIButton>
              </Box>
            </Box>
            <BulkEditConfirmationModal
              isProcessing={isLoading}
              onConfirm={handleSubmit}
              showConfirm={showConfirm}
              setShowConfirm={setShowConfirm}
              usageAlertConfig={usageAlertConfig}
            />
          </>
        }
      </div>
    </Main>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    contentCtr: {
      width: "100%",
      paddingTop: 18,
      marginBottom: 10,
    },
    buttonContainer: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      marginTop: "16px",
    },
    buttonGroup: {
      display: "flex",
      gap: "16px",
    },
    submitButton: {
      marginLeft: "auto",
      display: "block",
      marginTop: "16px",
    },
  }),
);

export default BulkEditUsageAlerts;
