import api from "../_app/api";
import { Flag } from "./types";

export const getFeatureFlags = async () => {
  let { data }: { data: Flag[] } = await api({
    method: "GET",
    url: "/feature-flags",
  });
  const overrides: Flag[] = [];

  return data?.concat(overrides);
};
