import React, { ReactNode } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import UIButton from "./UIButton";
import { createStyles, makeStyles } from "./UIStyles";
import { UITheme } from ".";
import { Box } from "./UIBox";

interface Props {
  title?: string;
  children?: ReactNode;
  open: boolean;
  setOpen: Function;
  onConfirm: Function | void | undefined;
  onCancel?: Function | void | undefined;
  disableOnSubmit?: boolean;
  onSubmitTitle?: string;
  onCancelTitle?: string;
  isDelete?: boolean;
  icon?: ReactNode;
  isProcessing?: boolean;
  width?: "xs" | "sm" | "md" | "lg" | "xl";
}

const UIConfirm = ({
  title,
  children,
  open,
  setOpen,
  onConfirm,
  onCancel,
  disableOnSubmit,
  onSubmitTitle,
  onCancelTitle,
  isDelete = false,
  icon,
  isProcessing,
  width = "xs",
}: Props) => {
  const classes = useStyles();

  const handleCancel = () => {
    if (onCancel) onCancel();
    setOpen(false);
  };

  return (
    <Dialog open={open} onClose={handleCancel} data-cy="confirm-dialog" fullWidth maxWidth={width}>
      <div className={classes.dialog}>
        {Boolean(title) && (
          <DialogTitle id="confirm-dialog">
            {icon ? (
              <Box className={classes.titleWithIcon}>
                {icon}
                {title}
              </Box>
            ) : (
              title
            )}
          </DialogTitle>
        )}
        <DialogContent>{children}</DialogContent>
        <DialogActions className={classes.buttons}>
          <UIButton variant="outlined" onClick={handleCancel} className={classes.cancelButton}>
            {onCancelTitle || "Cancel"}
          </UIButton>
          {isDelete ? (
            <UIButton
              data-cy="confirm-dialog-submit"
              variant="contained"
              className={classes.redDeleteButton}
              disabled={disableOnSubmit}
              onClick={() => {
                setOpen(false);
                if (onConfirm) onConfirm();
              }}
            >
              {onSubmitTitle || "Yes"}
            </UIButton>
          ) : (
            <UIButton
              data-cy="confirm-dialog-submit"
              variant="contained"
              disabled={disableOnSubmit}
              isLoading={isProcessing}
              onClick={() => {
                setOpen(false);
                if (onConfirm) onConfirm();
              }}
              color={"primary"}
            >
              {onSubmitTitle || "Yes"}
            </UIButton>
          )}
        </DialogActions>
      </div>
    </Dialog>
  );
};

const useStyles = makeStyles((theme: UITheme) =>
  createStyles({
    titleWithIcon: {
      display: "flex",
      alignItems: "center",
      gap: 1,
    },
    dialog: {
      margin: theme.spacing(2),
    },
    buttons: {
      marginTop: theme.spacing(2),
    },
    cancelButton: {
      padding: "7px 18px",
      marginRight: theme.spacing(1),
    },
    redDeleteButton: {
      color: "#ffffff",
      backgroundColor: theme.palette.error?.main,
    },
  }),
);

export default UIConfirm;
