import React, { useEffect } from "react";
import Snackbar, { SnackbarCloseReason } from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import { useStore } from "../hooks";

export default function GlobalAlertsProvider() {
  const { state } = useStore();
  const [open, setOpen] = React.useState(false);
  const classes = useStyles();
  const DEFAULT_DURATION = 4000;
  const DEFAULT_ERROR_DURATION = 7000;

  useEffect(() => {
    if (state.error || state.success || state.alert) {
      setOpen(true);
    }
  }, [state]);

  const handleClose = (event?: any, reason?: SnackbarCloseReason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  if (state.error) {
    // show error snackbar
    return (
      <Snackbar
        className={classes.snackbar}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={open}
        onClose={handleClose}
        autoHideDuration={state.error?.duration || DEFAULT_ERROR_DURATION}
      >
        <Alert onClose={handleClose} variant="filled" severity="error" data-cy="alert-error">
          {state.error?.message}
        </Alert>
      </Snackbar>
    );
  }
  if (state.success) {
    // show success snackbar
    return (
      <Snackbar
        className={classes.snackbar}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={open}
        onClose={handleClose}
        autoHideDuration={state.success?.duration || DEFAULT_DURATION}
      >
        <Alert onClose={handleClose} variant="filled" severity="success" data-cy="alert-success">
          {state.success?.message}
        </Alert>
      </Snackbar>
    );
  }
  if (state.alert) {
    // show generic snackbar
    return (
      <Snackbar
        className={classes.snackbar}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={open}
        onClose={handleClose}
        autoHideDuration={state.alert?.duration || DEFAULT_DURATION}
      >
        <Alert onClose={handleClose} variant="filled" severity="info" data-cy="alert">
          {state.alert?.message}
        </Alert>
      </Snackbar>
    );
  }
  return null;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    snackbar: {
      marginTop: "60px",
      right: "13px",
    },
  }),
);
