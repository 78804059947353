import React, { ChangeEvent, FocusEvent, useEffect, useState } from "react";
import { Theme, useTheme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import Main from "../../_app/layouts/Main";
import { Alert, Button, FormControl, MenuItem, Select, TextField } from "@mui/material";
import { useHistory, useLocation } from "react-router-dom";
import ErrorIcon from "@mui/icons-material/Error";
import CheckIcon from "@mui/icons-material/Check";
import MUIDataTable from "mui-datatables";
import { useMobileAssets } from "../../asset/hooks";
import { useGetFeatureProperty } from "../../feature/hooks";
import InputMask from "react-input-mask";
import { getSimDetails } from "../api";
import { SelectedParams } from "../../asset/screens/MobileManagement";
import { permissionCodes } from "../../permission/hooks";
import { AxiosResponse } from "axios";
import TableHeader from "../../_app/components/Table/TableHeader";
import UILoader from "../../_app/components/UILoader";
import { SimSwapOperation, SimType } from "../types";
import { getSimParams, getSimTypes } from "../utils";
import { capitalize } from "../../_app/utils/format";
import { featureFlagsMap } from "../../feature/utils";
import { useSimSwapUpdate } from "../hooks";

interface DataValue {
  id: string;
  cli: string;
  tag: string;
  network: string;
  simNumber: string;
  simType: string;
  simNumberNew: string;
  simNumberNewValidation: {
    isValidating: boolean;
    isValid?: boolean;
    error?: string;
  };
  simTypeNew: SimType;
  simLength: number;
  simPrefix: any;
}

interface DataMap {
  [cli: string]: DataValue;
}

const simTypes = getSimTypes();

export const SimSwap = () => {
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();
  const location = useLocation<SelectedParams>();
  const assets = location.state?.assets;
  const filters = location.state?.filters;
  const [canView, setCanView] = useState(false);
  const [data, setData] = useState<DataMap>({});
  const [tableData, setTableData] = useState<any[]>([]);
  const simSwapLimit = useGetFeatureProperty(featureFlagsMap.SIM_SWAP, "MAX_COUNT");

  const { data: assetsResp, isLoading } = useMobileAssets(
    0,
    parseInt(simSwapLimit?.toString() ?? "50"),
    filters,
    assets?.map((a) => a.cli),
    { enabled: !assets?.length && canView },
  );

  const { mutate: executeSimSwapUpdate, isLoading: isProcessing } = useSimSwapUpdate({
    onSuccess: () => {
      history.push("/mobile-management");
    },
  });

  useEffect(() => {
    const assetsList = assets?.length ? assets : assetsResp?.list;
    const parsed =
      assetsList?.reduce((map, asset) => {
        const simParams = getSimParams(asset?.network);

        return {
          ...map,
          [asset.cli]: {
            id: asset.id,
            cli: asset.cli,
            tag: asset.tag,
            network: asset.network,
            simNumber: asset.simNumber,
            simType: "-",
            simNumberNew: simParams?.simPrefix,
            simTypeNew: SimType.Triple,
            simLength: simParams?.simLength,
            simPrefix: simParams?.simPrefix,
          },
        };
      }, {}) || {};
    setData(parsed);
  }, [assets, assetsResp]);

  useEffect(() => {
    const onSimTypeChange = (simTypeNewValue: SimType, cli: string) => {
      setData({
        ...data,
        [cli]: { ...data[cli], simTypeNew: simTypeNewValue },
      });
    };

    const onSimNumberChange = (event: ChangeEvent<HTMLInputElement>, cli: string) => {
      setData({
        ...data,
        [cli]: { ...data[cli], simNumberNew: event.target.value },
      });
    };

    const onSimNumberBlur = async (ev: FocusEvent<HTMLInputElement>, item: DataValue) => {
      const val = ev.target.value;
      if (!val) return;
      try {
        setData((prev: DataMap) => ({
          ...prev,
          [item?.cli]: {
            ...(prev?.[item?.cli] || {}),
            simNumberNewValidation: {
              isValid: false,
              isValidating: true,
            },
          },
        }));
        const resp = await getSimDetails(val, item?.network);
        setData((prev) => ({
          ...prev,
          [item?.cli]: {
            ...(prev?.[item?.cli] || {}),
            simNumberNewValidation: {
              isValid: resp?.simStatus === "INACTIVE",
              error: resp?.simStatus !== "INACTIVE" ? "SIM already active" : undefined,
              isValidating: false,
            },
          },
        }));
      } catch (error) {
        setData((prev: DataMap) => ({
          ...prev,
          [item?.cli]: {
            ...(prev?.[item?.cli] || {}),
            simNumberNewValidation: {
              isValid: false,
              isValidating: false,
              error: (error as AxiosResponse).data?.message || "Invalid SIM number",
            },
          },
        }));
      }
    };
    const table =
      Object.values(data).map((item, index) => [
        item.cli,
        item.tag,
        item.network,
        item.simNumber,
        item.simType,
        <InputMask
          mask={
            [...(item.simPrefix ?? "")]?.reduce((prev, curr) => `${prev}\\${curr}`, "") +
            "9"?.repeat(item?.simLength - item?.simPrefix?.length)
          }
          alwaysShowMask={true}
          value={item.simNumberNew}
          onChange={(e) => onSimNumberChange(e, item.cli)}
          onBlur={(e: FocusEvent<HTMLInputElement>) => onSimNumberBlur(e, item)}
          disabled={item?.simNumberNewValidation?.isValidating}
        >
          {() => (
            <TextField
              className={classes.newSimNumber}
              variant="outlined"
              name={`simPrefix_${item?.cli}`}
              id={`simPrefix_${item?.cli}`}
              size="small"
              helperText={item?.simNumberNewValidation?.error}
              error={Boolean(item?.simNumberNewValidation?.error)}
              data-cy="new-sim-number"
              InputProps={{
                endAdornment: (() => {
                  if (item?.simNumberNewValidation?.isValidating) return <UILoader size="1.25rem" />;
                  if (typeof item?.simNumberNewValidation?.isValid !== "boolean") return null;
                  if (item?.simNumberNewValidation?.isValid) {
                    return <CheckIcon fontSize="small" htmlColor={theme.palette.success?.main} />;
                  } else {
                    return <ErrorIcon fontSize="small" color="error" />;
                  }
                })(),
              }}
            />
          )}
        </InputMask>,
        <FormControl variant="filled" size="small" style={{ width: "150px" }}>
          <Select
            value={item?.simTypeNew}
            variant="outlined"
            displayEmpty={true}
            data-cy={"select-sim-type-" + index}
            onChange={(event: any) => onSimTypeChange(event.target.value, item?.cli)}
          >
            {simTypes.map((option) => (
              <MenuItem value={option.value} key={option.value} data-cy={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>,
      ]) || [];
    setTableData(table);
  }, [classes, data, theme]);

  const canSubmit = Object.values(data)?.reduce((prev, curr) => {
    return Boolean(prev && curr?.simNumberNewValidation?.isValid && curr?.simTypeNew);
  }, true);

  const onSubmit = () => {
    if (canSubmit) {
      const operations: SimSwapOperation[] = Object.entries(data).map(([cli]) => {
        return {
          assetId: data?.[cli]?.id,
          simNumber: data?.[cli]?.simNumberNew,
          simType: data?.[cli]?.simTypeNew,
        };
      });
      return executeSimSwapUpdate(operations);
    }
  };

  const renderError = () => {
    if (assets?.length) return null;
    if (filters) return null;
    return (
      <Alert severity="error" className={classes.error}>
        No mobile numbers selected
      </Alert>
    );
  };

  return (
    <Main
      title="SIM Swap"
      data-cy="sim-swap-page"
      isLoading={false}
      featureFlag={featureFlagsMap.SIM_SWAP}
      accessPermission={permissionCodes.MOBILE_MANAGEMENT}
      accessGrantedCb={() => setCanView(true)}
      showNav={false}
      showToolbar={false}
      showFooter={false}
    >
      {renderError() || isLoading ? (
        <div className={classes.handling}>
          <UILoader size={50} />
        </div>
      ) : (
        <>
          <div className={classes.message}>
            <Alert severity="warning">
              Once you click <b>Activate all</b> please reboot the handset and allow up to 24 hours for the SIM card to be
              activated. If, after 24 hours, the SIM card has still not been activated, please contact your dedicated Customer
              Services team.
            </Alert>
          </div>
          <TableHeader>
            <Button
              variant="outlined"
              color="primary"
              onClick={onSubmit}
              disabled={!canSubmit || isProcessing}
              className={classes.activate}
              data-cy="activate-btn"
            >
              {isProcessing ? <UILoader size={24} /> : "Activate All"}
            </Button>
          </TableHeader>
          <div className={classes.tableCtr} data-cy="sim-swap-table">
            <MUIDataTable
              title=""
              data={tableData}
              columns={[
                "Identifier",
                "Tag",
                {
                  name: "Network",
                  options: { customBodyRender: (val) => capitalize(val) },
                },
                "SIM Number",
                "SIM Type",
                "New SIM Number",
                "New SIM Type",
              ]}
              options={{
                pagination: false,
                download: false,
                elevation: 1,
                print: false,
                responsive: "standard",
                selectToolbarPlacement: "none",
                filter: false,
                sort: false,
                search: false,
                serverSide: true,
                selectableRows: "none",
                rowHover: true,
                setTableProps: () => ({ size: "medium" }),
                setRowProps: (row) => ({ "data-cy": `row-id-${row?.[0]}` }),
                viewColumns: false,
              }}
            />
          </div>
        </>
      )}
    </Main>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    message: {
      marginTop: theme.spacing(1.5),
      marginBottom: theme.spacing(3),
      maxWidth: "80%",
      "& .MuiAlert-icon": {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      },
    },
    tableCtr: {
      width: "100%",
      marginTop: 0.5,
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
      "& div:first-child": {
        marginTop: 0,
      },
    },
    activate: {
      marginLeft: "auto",
    },
    error: {
      paddingLeft: "10px",
    },
    loader: {
      display: "flex",
      width: "100%",
      justifyContent: "center",
      alignItems: "center",
      marginTop: "calc(50vh - 200px)",
    },
    newSimNumber: {
      minWidth: "225px",
      textUnderlineOffset: "10px",
      "& .MuiOutlinedInput-adornedEnd": {
        paddingRight: theme.spacing(1),
      },
      "& .MuiFormHelperText-contained": {
        marginLeft: "5px",
      },
    },
    handling: {
      display: "flex",
      height: "70vh",
      flex: "1",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      gap: "35px",
      marginTop: "30px",
    },
  }),
);

export default SimSwap;
