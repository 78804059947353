import UIConfirm from "../../../_app/components/UIConfirm";
import React, { ReactNode } from "react";
import { AlertContact, UsageAlert } from "../../types";
import EmailIcon from "@mui/icons-material/Email";
import SmsIcon from "@mui/icons-material/Sms";
import WarningIcon from "@mui/icons-material/Warning";
import {
  createStyles,
  makeStyles,
  UIBox,
  UIList,
  UIListItem,
  UIListItemText,
  UITooltip,
  UITypography,
} from "../../../_app/components";

interface Props {
  showConfirm: boolean;
  setShowConfirm: (value: boolean) => void;
  usageAlertConfig: UsageAlert;
  onConfirm: Function;
  isProcessing?: boolean;
}

const BulkEditConfirmationModal = ({ showConfirm, setShowConfirm, usageAlertConfig, onConfirm, isProcessing }: Props) => {
  const classes = useStyles();

  return (
    <UIConfirm
      icon={<WarningIcon color="primary" />}
      onSubmitTitle="Confirm"
      title={"Confirm bulk edit changes"}
      open={showConfirm}
      setOpen={setShowConfirm}
      onConfirm={onConfirm}
      width={"sm"}
      isProcessing={isProcessing}
      disableOnSubmit={isProcessing}
    >
      <>
        Please review the changes below. These changes will replace your current settings for all selected usage alerts.
        <UIList dense>
          {Boolean(usageAlertConfig.name) && (
            <ChangeConfirmationItem primary={"Alert information"} secondary={`Name: ${usageAlertConfig.name}`} />
          )}
          {usageAlertConfig.criteria && (
            <ChangeConfirmationItem
              primary={"Alert criteria"}
              secondary={`${usageAlertConfig.criteria[0].measure.value} ${usageAlertConfig.criteria[0].threshold} ${usageAlertConfig.criteria[0].measure.unit}`}
            />
          )}
          {usageAlertConfig.alertAction?.notifyAssetsBySMS !== undefined && (
            <ChangeConfirmationItem
              primary={"Notify the user of the mobile number"}
              secondary={`User ${usageAlertConfig.alertAction?.notifyAssetsBySMS ? "will" : "won't"} be notified by SMS`}
            />
          )}
          {usageAlertConfig.alertAction?.contacts && (
            <ChangeConfirmationItem
              primary={"Notify contacts"}
              secondary={
                usageAlertConfig.alertAction?.contacts.length > 0 ? (
                  <UIBox className={classes.scrollableBox}>
                    {usageAlertConfig.alertAction?.contacts.map((contact) => (
                      <ContactListItem key={contact.contactId} contact={contact} />
                    ))}
                  </UIBox>
                ) : (
                  "All contacts will be removed"
                )
              }
            />
          )}
          {Boolean(usageAlertConfig.alertAction?.bar) && (
            <ChangeConfirmationItem primary={`Autobar`} secondary={usageAlertConfig.alertAction?.bar?.value} />
          )}
        </UIList>
      </>
    </UIConfirm>
  );
};

const ChangeConfirmationItem = ({ primary, secondary }: { primary?: string; secondary?: string | ReactNode }) => {
  return (
    <UIListItem>
      <UIListItemText
        primaryTypographyProps={{
          fontWeight: "bold",
          component: "div",
        }}
        secondaryTypographyProps={{
          component: "div",
        }}
        primary={primary}
        secondary={secondary}
      />
    </UIListItem>
  );
};

const ContactListItem = ({ contact }: { contact: AlertContact }) => {
  const classes = useStyles();

  return (
    <UIBox className={classes.contactListItem}>
      <UITypography variant="body2">{`${contact.forename} ${contact.surname} - ${contact.email}`}</UITypography>
      <UIBox sx={{ display: "flex", gap: 1 }}>
        <UITooltip placement={"top"} title={`Email notification ${contact.emailNotification ? "enabled" : "disabled"}`}>
          <EmailIcon color={contact.emailNotification ? "success" : "error"} fontSize="small" />
        </UITooltip>
        <UITooltip placement={"top"} title={`SMS notification ${contact.smsNotification ? "enabled" : "disabled"}`}>
          <SmsIcon color={contact.smsNotification ? "success" : "error"} fontSize="small" />
        </UITooltip>
      </UIBox>
    </UIBox>
  );
};

const useStyles = makeStyles(() =>
  createStyles({
    contactListItem: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      gap: 1,
    },
    scrollableBox: {
      maxHeight: "300px",
      overflowY: "auto",
    },
    greenIcon: {
      color: "forestgreen",
    },
    redIcon: {
      color: "red",
    },
  }),
);

export default BulkEditConfirmationModal;
