import api from "../_app/api";
import { SortParam } from "../_app/components/Table/helpers";
import { filtersIdMap } from "../filter/utils";
import { Query } from "../filter/types";
import { ServiceAnalysisColumn, ServiceAnalysisResponse } from "./types";

export const getServiceAnalysisColumns = async () => {
  let { data }: { data: ServiceAnalysisColumn[] } = await api({
    method: "GET",
    url: "/services/columns",
  });
  return data;
};

export const getServiceAnalysis = async (
  startingToken: string,
  limit: number,
  sort: SortParam[],
  queries: Query[],
  columns: string[]
) => {
  const params = new URLSearchParams();
  if (startingToken) params.append("starting-token", startingToken);
  if (!!sort.length) sort.map((s) => params.append("sort", [s.col, s.dir].join(",")));
  params.append("size", limit.toString());
  queries.forEach((q) => {
    if (q.id === filtersIdMap.SERVICES_BILL_PERIOD) params.append(q.id, q.value.replace("UNBILLED-", ""));
    else params.append(q.id, q.value);
  });
  columns.forEach((c) => params.append("columns", c));
  const { data }: { data: ServiceAnalysisResponse } = await api({
    method: "GET",
    url: "/services",
    context_injection: true,
    params,
  });
  return data;
};

export const getServiceAnalysisFile = async (queries: Query[]) => {
  const params = new URLSearchParams();
  queries.forEach((q) => {
    if (q.id === filtersIdMap.SERVICES_BILL_PERIOD) params.append(q.id, q.value.replace("UNBILLED-", ""));
    else params.append(q.id, q.value);
  });
  const response = await api({
    method: "GET",
    url: "/services/export",
    context_injection: true,
    params,
  });
  return response;
};
