import { IdValueQuery } from "../_app/api";

export enum RequestStatus {
  PENDING_CREATE = "PENDING_CREATE",
  PENDING_UPDATE = "PENDING_UPDATE",
  PENDING_DELETE = "PENDING_DELETE",
  ACTIVE = "ACTIVE",
  FAILED = "FAILED",
}

export interface UsageAlert {
  id: string;
  name: string;
  accountId: string;
  accountCode: string;
  addBar: BarAlertAction;
  cli: string;
  type: string;
  product: string;
  level: string;
  criteria: AlertCriteria[];
  alertAction: AlertAction;
  requestStatus: RequestStatus;
  requestStatusUpdatedAt: string;
  description?: string;
  latestBreachAlertedOn?: string;
}

export interface CreateUsageAlert {
  id: string;
  name: string;
  accountId: string;
  cli: string[];
  filters?: IdValueQuery[];
  type: string;
  product: string;
  level: string;
  criteria: AlertCriteriaUpdate[];
  alertAction: AlertActionUpdate;
}

export interface UpdateUsageAlert {
  alertId: string;
  alertDetailUpdateConfig: {
    name: string;
    alertAction: AlertActionUpdate;
    criteria: AlertCriteriaUpdate[];
  };
}

export interface BillingAlertBulkEdit {
  alertIds: string[];
  name?: string;
  criterion?: AlertCriteria;
  contacts?: AlertContact[];
  notifyAssetsBySMS?: boolean;
  bar?: BarAlertAction;
}

export interface GetUsageAlertsResponse {
  list: UsageAlert[];
  nextPageToken: string;
}

export interface UsageAlertHistory {
  tenantId: string;
  alertId: string;
  accountId: string;
  cli: string;
  name: string;
  type: string;
  product: string;
  criteria: AlertCriteria[];
  alertAction: AlertAction;
  alertBreach: {
    usageValue: string | number;
    usageValueUnit: string;
    alertedOn: string;
    billingPeriod: string;
    recipient: AlertHistoryRecipient[];
    status?: string;
    start?: string;
    end?: string;
  };
}

export interface AlertHistoryRecipient {
  emailAddress: string;
  mobileNumber: string;
  notificationSystemReference: string;
  notifiedOn: string;
  success: boolean;
  forename: string;
  surname: string;
}

export interface GetUsageAlertsHistoryResponse {
  list: UsageAlertHistory[];
  nextPageToken: string;
}

export interface AlertContact {
  contactId: string;
  forename: string;
  surname: string;
  email: string;
  mobileNumber: string;
  emailNotification: boolean;
  smsNotification: boolean;
}

export interface AlertAction {
  bar?: BarAlertAction;
  contacts: AlertContact[];
  notifyAssetsBySMS: boolean;
}

export interface AlertActionUpdate {
  bar?: string;
  contacts?: AlertContact[];
  notifyAssetsBySMS?: boolean;
}

export interface BarAlertAction {
  name: string;
  value?: string;
}

export interface AlertMeasure {
  value?: string;
  name: string;
  assetType: string;
  unit: string;
}

export interface ProductType {
  id: string;
  label: string;
}

export interface AlertCriteria {
  measure: AlertMeasure;
  threshold: string;
}

export interface AlertCriteriaUpdate {
  measure: string;
  threshold: string;
}

export interface BundleAlertAccount {
  accountId: string;
  accountCode: string;
  companyName: string;
  enabled: boolean;
}

export interface BundleAlertIndividual {
  assetId: string;
  cli: string;
  tag: string;
  costCentre: string;
  enabled: boolean;
}

export interface GetBundleAlertsAccountResponse {
  list: BundleAlertAccount[];
  page: number;
  pageSize: number;
  total: number;
}

export interface GetBundleAlertsIndividualResponse {
  list: BundleAlertIndividual[];
  page: number;
  pageSize: number;
  total: number;
}

export interface BundleAlertUpdate {
  id: string;
  enabled: boolean;
}
