import React from "react";
import { createStyles, makeStyles, UIButton, UILoader } from "../../../_app/components";
import WorldwideDataCapDetail from "./data-roaming/WorldwideDataCapDetail";
import DetailsCard from "./DetailsCard";
import DetailItem from "./DetailItem";
import { Asset } from "../../types";
import { useHasFeature } from "../../../feature/hooks";
import { featureFlagsMap } from "../../../feature/utils";
import { useSingleMobileDetails } from "../../hooks";

interface Props {
  showBars: () => void;
  hasBars: boolean;
  asset: Asset;
}

export const MobileDetails = ({ showBars, hasBars, asset }: Props) => {
  const classes = useStyles();
  const { data: mobileAsset, isLoading } = useSingleMobileDetails(asset.id);

  const hasWorldwideDataCap = useHasFeature(featureFlagsMap.DATA_ROAMING);

  return isLoading ? (
    <UILoader data-cy={"wdr-loader"} size={25} />
  ) : (
    <DetailsCard title="Mobile Details">
      <>
        <DetailItem title="Provider" value={mobileAsset?.network ?? "N/A"} />
        <DetailItem title="SIM Number" value={mobileAsset?.simNumber ?? "N/A"} />
        {hasBars && (
          <DetailItem
            title="Bars"
            value=""
            action={
              <UIButton
                aria-label="manage-btn"
                variant="text"
                onClick={showBars}
                className={classes.button}
                data-cy="manage-btn"
                id="manage-btn"
              >
                Manage
              </UIButton>
            }
          />
        )}
        {hasWorldwideDataCap && <WorldwideDataCapDetail assetId={asset.id} />}
      </>
    </DetailsCard>
  );
};

const useStyles = makeStyles(() =>
  createStyles({
    button: {
      justifyContent: "flex-end",
    },
  }),
);

export default MobileDetails;
