import { humanize } from "../_app/utils/format";
import { isArray } from "../_app/utils";
import api, { IdValueQuery } from "../_app/api";
import { getInvoiceNumbers } from "../billing/api";
import { UNBILLED, formatPeriodToMonthYear, mapInvoiceNumber } from "../billing/utils";
import { formatCostCentreCode } from "../account/utils";
import { Filter, UsersFilter, FilterValues } from "./types";
import { MIN_FILTERS_COUNT } from "./utils";

export const getAllFilters = async (usage: string) => {
  const { data }: { data: Filter[] } = await api({
    method: "GET",
    url: "/filters/" + usage,
  });
  data.forEach(async (filter, index) => {
    filter.showToggle = index < MIN_FILTERS_COUNT;
    if (filter.id === "61" || filter.id === "63") {
      filter.valueDisplayFunc = humanize;
    } else if (filter.id === "3" || filter.id === "23" || filter.id === "34" || filter.id === "44" || filter.id === "54") {
      filter.valueDisplayFunc = formatCostCentreCode;
    } else if (filter.id === "12" || filter.id === "28") {
      filter.valueDisplayFunc = mapInvoiceNumber;
      const invoiceNumbers = await getInvoiceNumbers();
      localStorage.setItem("INVOICE_NUMBERS", JSON.stringify(invoiceNumbers));
    } else if (filter.name === "Bill Period" && filter.type !== "PERIOD_RANGE") {
      filter.valueDisplayFunc = formatPeriodToMonthYear;
    }
  });
  return data;
};

export const getUsersFilters = async (usage: string) => {
  const { data }: { data: UsersFilter } = await api({
    method: "GET",
    url: `/filters/${usage}/user`,
  });
  return data;
};

export const getFilterValues = async (resource: string | undefined, page?: number, search?: string, pageSize = 25) => {
  const params: any = {};
  if (search) {
    params["search-term"] = search;
  }
  if (page || page === 0) {
    params["page"] = page;
    params["size"] = pageSize;
  }
  let { data }: { data: FilterValues | any } = await api({
    method: "GET",
    url: resource,
    params,
    context_injection: true,
  });

  // Add false value representing Unassigned option in Cost Center filter
  if (data?.length && resource?.includes("costcentres")) {
    data?.unshift("false");
  }

  if (resource === "/billed-periods" && isArray(data)) {
    data = [UNBILLED, ...data];
  }

  return data;
};

export const saveFilters = async (usage: string, filters: UsersFilter) => {
  const { data }: { data: UsersFilter } = await api({
    method: "POST",
    url: `/filters/${usage}/user`,
    data: {
      ...filters,
    },
  });
  return data;
};

export const deleteFilter = async (usage: string, filterId: string) => {
  const { data } = await api({
    method: "DELETE",
    url: `/filters/${usage}/user/${filterId}`,
  });
  return data;
};

export function convertFiltersToQueries(params: any) {
  const { filters, ...rest } = params;
  const parsed: any = { ...rest };
  if (isArray(filters)) parsed.queries = {};
  filters?.forEach((f: IdValueQuery) => {
    if (parsed.queries?.[f?.id]) {
      parsed.queries?.[f?.id]?.push(f?.value);
    } else {
      parsed.queries[f?.id] = [f?.value];
    }
  });
  return parsed;
}
