import { SignedInUser } from "./types";

const COGNITO_TOKEN_ISSUER_URL = "https://cognito-idp.eu-west-2.amazonaws.com";

// Make sure to call this function after authConfig is set when using default value
export const isCognitoUser = (issuer?: string) => {
  if (issuer) {
    return issuer.startsWith(COGNITO_TOKEN_ISSUER_URL);
  } else {
    return window.authConfig?.tokenIssuerUrl.startsWith(COGNITO_TOKEN_ISSUER_URL);
  }
};

export const isImpersonatedUser = (user?: SignedInUser): boolean => {
  if (!user) return false;
  if (!isCognitoUser()) return user.profile.act;

  const keyPrefix = `CognitoIdentityServiceProvider.${window.authConfig?.customerPortalClientId}`;
  return localStorage.getItem(`${keyPrefix}.LastAuthUser`) === "impersonated_user";
};
