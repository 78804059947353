import React, { ChangeEvent, useEffect, useState } from "react";
import { useAlertMeasures } from "../../hooks";
import { useValidation } from "../../../form/hooks";
import { humanize } from "../../../_app/utils/format";
import InfoIcon from "@mui/icons-material/Info";
import SelectDropdown from "../../../form/components/SelectDropdown";
import { AlertMeasure, UsageAlert } from "../../types";
import {
  createStyles,
  makeStyles,
  UIChip,
  UIGrid,
  UIInputAdornment,
  UILoader,
  UIPaper,
  UITextField,
  UITheme,
  UITooltip,
  UITypography,
} from "../../../_app/components";

interface Props {
  usageAlert: UsageAlert;
  setAlert: Function;
  setErrors?: Function;
  isEdit?: boolean;
  isBulkEdit?: boolean;
}

export const SelectCriteria = ({ usageAlert, setAlert, isEdit, isBulkEdit, setErrors }: Props) => {
  const classes = useStyles();
  const [alertCriteriaParsed, setAlertCriteriaOptions] = useState([] as any);

  const { data: alertMeasures, isFetching } = useAlertMeasures(usageAlert?.product?.toUpperCase(), {
    enabled: !!usageAlert?.product,
  });

  const unit = alertMeasures?.find((m) => m.name === usageAlert?.criteria?.[0].measure?.name)?.unit;

  // Validation
  const validationConfig: any = {
    threshold: {
      required: true,
      validate: (val: any) => {
        if (val && (isNaN(val) || val < 0)) return "Invalid threshold";
        if (unit !== "£" && val?.indexOf?.(".") > 0 && !Number.isInteger(val)) {
          return "Whole number required";
        }
      },
    },
  };

  const { validationErrors, validateSingle, validateAll, setValidationErrors } = useValidation(validationConfig, {
    threshold: usageAlert?.criteria?.[0]?.threshold,
  });

  useEffect(() => {
    if (unit) validateAll();
    // @hack
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unit]);

  useEffect(() => {
    if (!usageAlert.criteria) {
      setValidationErrors({});
    }
  }, [usageAlert?.criteria]);

  useEffect(() => {
    setErrors?.(validationErrors);
  }, [validationErrors]);

  useEffect(() => {
    const criteriaOptions = alertMeasures?.map((action: AlertMeasure) => {
      return {
        id: action.name,
        label: action.value,
        value: action.name,
      };
    });

    setAlertCriteriaOptions(criteriaOptions);
  }, [alertMeasures, usageAlert]);

  const onAlertCriteriaMeasureChange = (e: ChangeEvent<HTMLInputElement>) => {
    const criteriaLabel = alertMeasures?.find((m) => m.name === e.target.value)?.value;

    setAlert((old: UsageAlert) => {
      return {
        ...old,
        criteria: [
          {
            ...(old.criteria?.[0] || {}),
            measure: { name: e.target.value, assetType: "", unit: "", value: criteriaLabel },
          },
        ],
      };
    });
  };

  const onAlertCriteriaThresholdChange = (e: ChangeEvent<HTMLInputElement>) => {
    setAlert((old: UsageAlert) => {
      return {
        ...old,
        criteria: [
          {
            ...(old.criteria?.[0] || {}),
            threshold: e.target.value,
            measure: { ...old.criteria?.[0]?.measure, unit: unit },
          },
        ],
      };
    });
  };

  const onNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    setAlert((old: UsageAlert) => {
      return {
        ...old,
        name: e.target.value,
      };
    });
  };

  const tooltipText = `The alert will be sent when the current month's usage exceeds the below criteria.`;

  if (isFetching) return <UILoader />;

  return (
    <div>
      {Boolean(isEdit || isBulkEdit) && (
        <UIPaper className={classes.paper}>
          <div className={classes.tooltip}>
            <UITypography variant="h3" gutterBottom>
              Alert information
            </UITypography>
          </div>
          <UIGrid container direction="row" spacing={1} alignItems="center">
            {Boolean(isEdit || isBulkEdit) && (
              <UIGrid item xs={12} md={3}>
                <UITextField
                  required={isEdit}
                  fullWidth
                  variant="outlined"
                  size="small"
                  name="name"
                  label="Name"
                  value={usageAlert.name || ""}
                  onChange={onNameChange}
                />
              </UIGrid>
            )}

            <UIGrid item xs={12} md={9} className={classes.selectionChips}>
              <UIChip
                label={`Level: ${humanize(usageAlert.level, ["CLI"])}`}
                data-cy="level-chip"
                size="small"
                variant="outlined"
              />
              <UIChip
                label={`Product Type: ${humanize(usageAlert.product)}`}
                data-cy="type-chip"
                variant="outlined"
                size="small"
              />
              {Boolean(usageAlert.cli) && (
                <UIChip label={`Identifier: ${usageAlert.cli}`} data-cy="identifier-chip" variant="outlined" size="small" />
              )}
            </UIGrid>
          </UIGrid>
        </UIPaper>
      )}
      <UIPaper className={classes.paper}>
        <div className={classes.tooltip}>
          <UITypography variant="h3" gutterBottom>
            When do you want to be alerted?
          </UITypography>
          <UITooltip title={tooltipText} placement="right" arrow className={classes.infoIcon}>
            <InfoIcon color="secondary" data-cy="tooltip-criteria" />
          </UITooltip>
        </div>
        <UIGrid container direction="row" spacing={1}>
          <UIGrid item xs={12} md={3}>
            <SelectDropdown
              ctrClass={classes.textField}
              name="measure"
              label="Alert Criteria"
              value={usageAlert?.criteria?.[0]?.measure?.name || ""}
              data={alertCriteriaParsed}
              onChange={onAlertCriteriaMeasureChange}
            />
          </UIGrid>
          <UIGrid item xs={12} md={3}>
            <UITextField
              fullWidth
              variant="outlined"
              size="small"
              className={classes.textField}
              name="threshold"
              label="Threshold"
              value={usageAlert?.criteria?.[0]?.threshold || ""}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                onAlertCriteriaThresholdChange(event);
                validateSingle(event.target.name, event.target.value);
              }}
              error={Boolean(validationErrors.threshold)}
              helperText={validationErrors.threshold}
              InputProps={{
                [unit === "£" ? "startAdornment" : "endAdornment"]: (
                  <UIInputAdornment position="start">{unit || ""}</UIInputAdornment>
                ),
              }}
            />
          </UIGrid>
          {(unit === "Mb" || unit === "MB") && (
            <UIGrid item alignContent="center">
              <UITooltip title={"1 GB = 1024 MB"} placement="right" arrow>
                <InfoIcon color="secondary" className={classes.icon} data-cy="tooltip-treshold" />
              </UITooltip>
            </UIGrid>
          )}
        </UIGrid>
      </UIPaper>
    </div>
  );
};

const useStyles = makeStyles((theme: UITheme) =>
  createStyles({
    paper: {
      padding: theme.spacing(3),
      marginBottom: theme.spacing(2),
    },
    textField: {
      marginBottom: theme.spacing(3),
    },
    alert: {
      width: "max-content",
      marginBottom: 12,
    },
    selectionChips: {
      display: "flex",
      justifyContent: "flex-end",
      width: "100%",
      gap: theme.spacing(1),
    },
    tooltip: {
      display: "flex",
      gap: theme.spacing(1.5),
      maxWidth: "400px",
    },
    icon: {
      marginTop: theme.spacing(1),
      marginLeft: theme.spacing(1),
      cursor: "pointer",
    },
    infoIcon: {
      cursor: "pointer",
      marginTop: "-3px",
    },
  }),
);
