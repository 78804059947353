import { useState } from "react";
import { isEmpty } from "../_app/utils";

export function useValidation(config: any, target: any) {
  const [validationErrors, setValidationErrors] = useState<any>({});

  const validateSingle = (key: string, val: any) => {
    let error: string = "";
    let validateFunc = config?.[key]?.validate;
    if (typeof validateFunc === "function") {
      error = validateFunc(val);
    }
    if (config?.[key]?.required && isEmpty(val)) error = "Required field";
    else if (config?.[key]?.maxLength && val.length > config?.[key]?.maxLength)
      error = "Maximum length is " + config?.[key]?.maxLength;
    else if (config?.[key]?.minLength && val.length < config?.[key]?.minLength)
      error = "Minimum length is " + config?.[key]?.minLength;
    setValidationErrors((prevState: any) => ({
      ...prevState,
      [key]: error,
    }));
    return error;
  };

  const validateAll = () => {
    const errors = Object.keys(config)?.map((key) => validateSingle(key, target?.[key]));
    return errors?.filter((e) => Boolean(e));
  };

  return {
    setValidationErrors,
    validationErrors,
    validateSingle,
    validateAll,
  };
}
