import { useQuery, useQueryClient } from "react-query";
import { AjaxResponse } from "../_app/api";
import { getFeatureFlags } from "./api";
import { Flag } from "./types";
import { useStore } from "../_app/hooks";

export function useFeatureFlags(options = {}) {
  const queryClient = useQueryClient();
  const { state } = useStore();
  const sessionUser = state.contextHierarchy?.lastParentId;

  return useQuery<Flag[], AjaxResponse>(["featureFlags"], () => getFeatureFlags(), {
    staleTime: 0,
    enabled: Boolean(sessionUser),
    onSuccess: () => {
      // Once the data is successfully fetched for the first time set a default stale time 15 minutes
      queryClient.setQueryDefaults("featureFlags", {
        staleTime: 1000 * 60 * 15,
      });
    },
    ...options,
  });
}

export function useGetFeatureProperty(key: string, property: string) {
  const { data: features } = useFeatureFlags();

  return features?.find((feature) => feature?.uid === key)?.properties?.[property];
}

export function useHasFeature(key: string | undefined) {
  const { data: features } = useFeatureFlags();

  return features?.some((feature) => feature?.uid === key && feature?.enable);
}

export function useHasAnyFeature(keys: string[] | string = []) {
  const { data: features } = useFeatureFlags();

  if (typeof keys === "string") {
    return features?.some((feature) => feature?.uid === keys && feature?.enable);
  }

  return keys?.some((key) => features?.some((feature) => feature?.uid === key && feature?.enable));
}
