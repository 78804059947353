import React from "react";
import { useHistory } from "react-router-dom";
import { Grid, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import { useFeedbackAlerts, useStore } from "../../_app/hooks";
import { useQueryClient } from "react-query";
import actions from "../../_app/store/Actions";
import { useIdentityUser } from "../../auth/hooks";
import { deleteLogicMonitorToken } from "../../logic-monitor/api";
import { SingleActionCard } from "../../_app/components/SingleActionCard";
import { Group } from "../types";

interface GroupCardProps {
  group: Group;
  lg: any;
}

const GroupCard = ({ group, lg, ...rest }: GroupCardProps) => {
  const { dispatch } = useStore();
  const { setFeedbackAlertSuccess } = useFeedbackAlerts();
  const classes = useStyles();
  const history = useHistory();
  const queryClient = useQueryClient();
  const { data: user } = useIdentityUser();
  const userId = user?.profile?.sub;

  const handleSelectGroup = async () => {
    dispatch({
      type: actions.SET_CONTEXT_HIERARCHY,
      payload: {
        ...group,
        userId: userId,
        lastParentId: group?.id,
      },
    });
    setFeedbackAlertSuccess("Selection successful");
    queryClient?.invalidateQueries();
    deleteLogicMonitorToken();
    history.replace(`/`);
  };

  return (
    <Grid item sm={12} md={6} lg={lg} className={classes.cardWrap} {...rest}>
      <SingleActionCard
        height="180px"
        actionTitle="Select"
        actionFunction={handleSelectGroup}
        actionTestId="select-group-button"
        elevation={2}
      >
        <div className={classes.cardContent}>
          <Typography variant="h4">{group?.name}</Typography>
        </div>
      </SingleActionCard>
    </Grid>
  );
};

const useStyles = makeStyles(() =>
  createStyles({
    cardWrap: {
      maxWidth: "356px",
    },
    cardContent: {
      margin: "auto",
      textAlign: "center",
    },
  }),
);

export default GroupCard;
