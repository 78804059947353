import axios, { AxiosError, AxiosInstance, AxiosPromise, AxiosRequestConfig, AxiosResponse } from "axios";
import config from "../config";
import { logout, renewTokens } from "../../auth/sso-api";
import { sleep } from "../utils";
import { getCachedContextHierarchy, getContextPath } from "../../context/utils";
import { isCognitoUser } from "../../auth/utils";
import { fetchAuthSession } from "aws-amplify/auth";
import { getIdentityUser } from "../../auth/api";

export interface IdValueQuery {
  id: string;
  value: string;
}

const ERROR_MESSAGE_MAPPING: { [key: string]: string } = {
  "Unique index violation": "A role with this name already exists, please choose a unique name.",
};

export interface AjaxRequestConfig extends AxiosRequestConfig {
  context_injection?: boolean;
  skip_bearer_injection?: boolean;
  skip_context_path_injection?: boolean;
  skip_token_refresh?: boolean;
  serialize?: boolean;
}

export interface AjaxInstance extends AxiosInstance {
  (config: AjaxRequestConfig): AxiosPromise;
}

export interface AjaxResponse extends AxiosResponse {}

const ajax: AjaxInstance = axios.create();

const init = () => {
  ajax.defaults.baseURL = config?.configEndpoints?.baseEndpoint;
  ajax.defaults.headers.post["Content-Type"] = "application/json";
  ajax.defaults.headers.put["Content-Type"] = "application/json";
  ajax.interceptors.request.use(requestIntercept, (error) => Promise.reject(error));
  ajax.interceptors.response.use((success) => success, responseFailIntercept);
  return ajax;
};

const requestIntercept = async (request: AjaxRequestConfig) => {
  // Without Advanced Security turned on (costs around $4k/month), Cognito can neither adjust Access Token scopes nor claims.
  // Hence, using ID token instead, to avoid excessive costs.
  // See https://auth0.com/blog/id-token-access-token-what-is-the-difference
  // See https://aws.amazon.com/blogs/security/how-to-customize-access-tokens-in-amazon-cognito-user-pools
  // TODO Remove the Token Issuer URL fork once Affinity SSO tenants are migrated to Cognito.
  if (!request?.skip_bearer_injection) {
    const user = await getIdentityUser();
    if (isCognitoUser() && user?.id_token) {
      const session = await fetchAuthSession();
      request.headers.Authorization = `Bearer ${session?.tokens?.idToken?.toString()}`;
    } else if (!isCognitoUser() && user?.access_token) {
      request.headers.Authorization = `Bearer ${user?.access_token}`;
    }
  }

  const contextPath = getContextPath() || getCachedContextHierarchy()?.lastParentId;
  if (!request?.skip_context_path_injection && contextPath) {
    request.headers["X-Hub-Top-Account-Id"] = contextPath;
  }
  if (request?.context_injection) {
    const contextId = getCachedContextHierarchy()?.id;
    if (typeof request.params?.append === "undefined") {
      request.params = {
        "account-id": contextId,
        ...request.params,
      };
    } else {
      request.params?.append("account-id", contextId);
    }
  }
  if (request?.serialize) {
    request.paramsSerializer = function (params) {
      const parsed = new URLSearchParams();
      for (let key of Object.keys(params)) {
        if (key === "queries") {
          params?.queries?.forEach((q: IdValueQuery) => parsed.append(q?.id, q?.value));
        } else if (params?.[key] !== undefined) {
          const isArray = Array.isArray(params?.[key]);
          parsed.append(key, isArray ? params?.[key]?.toString() : params?.[key]);
        }
      }
      return parsed.toString();
    };
  }
  return request;
};

const responseFailIntercept = async (error: AxiosError) => {
  const original: AjaxRequestConfig = error?.config;
  const response = error?.response;

  if (response?.data?.message) {
    response.data.message = mapErrorMessage(response.data.message);
  }

  if (original?.skip_token_refresh || response?.status !== 401) {
    return Promise.reject(response);
  }

  if (response?.status === 401 && isCognitoUser()) {
    window.location.href = "/login";
  }

  if (typeof renewTokens === "function") {
    if (isRenewing) {
      return new Promise((resolve, reject) => {
        failedQueue.push({
          request: original,
          resolve,
          reject,
        });
      });
    }
    isRenewing = true;

    try {
      await renewTokens();
      await sleep(500); // @hack - renewTokens() occasionally resolves early
      processQueue();
      isRenewing = false;
      return new Promise((resolve) => {
        resolve(
          ajax({
            ...original,
            skip_token_refresh: true,
          }),
        );
      });
    } catch (error) {
      isRenewing = false;
      return logout();
    }
  }
  return Promise.reject(response);
};

let isRenewing = false;
let failedQueue: any[] = [];

const processQueue = () => {
  failedQueue.forEach((promise: any) => {
    if (promise?.request) {
      axios(promise?.request)
        .then((resp) => promise?.resolve(resp))
        .catch((err) => promise?.reject(err));
    }
  });
  failedQueue = [];
};

export const mapErrorMessage = (error: string): string => {
  return ERROR_MESSAGE_MAPPING[error] || error;
};

export default init();
