import React, { useCallback, useEffect, useState } from "react";

import InfoIcon from "@mui/icons-material/Info";
import WarningIcon from "@mui/icons-material/Warning";
import MUIDataTable from "mui-datatables";
import SelectDropdown from "../../../form/components/SelectDropdown";
import { useAlertActions } from "../../hooks";
import { useContactsInfinite } from "../../../contacts/hooks";
import { AlertContact, UsageAlert } from "../../types";
import { Contact } from "../../../contacts/types";
import CloseIcon from "@mui/icons-material/Close";
import {
  createStyles,
  makeStyles,
  UIAlert,
  UIButton,
  UICheckbox,
  UIFormControlLabel,
  UIGrid,
  UIPaper,
  UITheme,
  UITooltip,
  UITypography,
} from "../../../_app/components";

interface Props {
  usageAlert?: UsageAlert;
  setAlert: Function;
  bulkEdit?: boolean;
}

const createContactStr = (firstName: string, lastName: string, email: string, mobileNumber: string) => {
  return [`${firstName} ${lastName}`, email, mobileNumber].filter((str) => !!str).join(", ");
};

export const SelectAction = ({ usageAlert, setAlert, bulkEdit = false }: Props) => {
  const classes = useStyles();
  const [barActions, setBarActions] = useState([] as any);

  const [parsedTableContacts, setParsedContacts] = React.useState<any[]>([]);

  const { data: alertActions } = useAlertActions(usageAlert?.product!!, { enabled: Boolean(usageAlert?.product) });
  const { data: contacts, fetchNextPage, hasNextPage } = useContactsInfinite();

  const contactsParsed = contacts?.pages.reduce(
    (acc: any, page: any) => [
      ...acc,
      ...page.list.map((c: Contact) => ({
        id: c.id,
        label: createContactStr(c.firstName, c.lastName, c.email, c.mobileNumber),
        value: c,
        disabled: usageAlert?.alertAction?.contacts?.some((contact: AlertContact) => contact.contactId === c.id),
      })),
    ],
    [],
  );

  useEffect(() => {
    const barActions = alertActions?.map((action) => {
      return {
        id: action.name,
        label: action.value,
        value: action.name,
      };
    });
    setBarActions(barActions);
  }, [alertActions, usageAlert]);

  const onContactChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const val: any = e?.target?.value;

    setAlert((old: UsageAlert) => {
      return {
        ...old,
        alertAction: {
          ...(old.alertAction || {}),
          contacts: [
            ...(old.alertAction?.contacts || []),
            {
              contactId: val?.id,
              email: val?.email,
              emailNotification: !!val?.email,
              forename: val?.firstName,
              surname: val?.lastName,
              mobileNumber: val?.mobileNumber,
              smsNotification: false,
            },
          ],
        },
      };
    });
  };

  const onAlertActionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const key = e?.target.name;
    const val = e?.target.value;
    const checked = e?.target.checked;

    setAlert((old: UsageAlert) => {
      return {
        ...old,
        alertAction: {
          ...old.alertAction,
          [key]: key == "notifyAssetsBySMS" ? checked : val,
        },
      };
    });
  };

  const onBarChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const key = e?.target.name;
    const val = e?.target.value;
    const barLabel = alertActions?.find((m) => m.name === val)?.value;

    setAlert((old: UsageAlert) => {
      return {
        ...old,
        alertAction: {
          ...old.alertAction,
          [key]: { name: val, value: barLabel },
        },
      };
    });
  };

  const onCheckboxCheck = useCallback(
    <K extends keyof AlertContact>(key: K, id: string) => {
      setAlert((oldAlert: UsageAlert) => {
        const contacts = oldAlert?.alertAction?.contacts.map((contact) => {
          if (contact.contactId === id) {
            return {
              ...contact,
              [key]: !contact[key] as AlertContact[K],
            };
          }
          return contact;
        });

        return {
          ...oldAlert,
          alertAction: {
            ...oldAlert.alertAction,
            contacts,
          },
        };
      });
    },
    [usageAlert?.alertAction?.contacts],
  );

  const removeContact = useCallback(
    (id: string) => {
      setAlert((oldAlert: UsageAlert) => {
        return {
          ...oldAlert,
          alertAction: {
            ...oldAlert.alertAction,
            contacts: oldAlert.alertAction?.contacts?.filter((c: AlertContact) => c.contactId !== id),
          },
        };
      });
    },
    [usageAlert?.alertAction?.contacts],
  );

  useEffect(() => {
    const parsed = usageAlert?.alertAction?.contacts?.map((contact: AlertContact) => {
      return [
        createContactStr(contact.forename, contact.surname, contact.email, contact.mobileNumber),
        Boolean(contact.email) && (
          <UICheckbox
            name="emailNotification"
            checked={contact.emailNotification && !!contact.email}
            onChange={() => onCheckboxCheck("emailNotification", contact.contactId)}
            disabled={!contact.email || !contact.smsNotification}
            color="primary"
          />
        ),
        Boolean(contact.mobileNumber) && (
          <UICheckbox
            name="smsNotification"
            checked={contact.smsNotification && !!contact.mobileNumber}
            onChange={() => onCheckboxCheck("smsNotification", contact.contactId)}
            disabled={!contact.mobileNumber || !contact.emailNotification}
            color="primary"
          />
        ),
        <UIButton
          startIcon={<CloseIcon />}
          variant="text"
          color="primary"
          onClick={() => removeContact(contact.contactId)}
          data-cy="delete"
        >
          Remove
        </UIButton>,
      ];
    });

    setParsedContacts(parsed || []);
  }, [usageAlert?.alertAction, removeContact, onCheckboxCheck]);

  const canAddBar = usageAlert?.level === "CLI" && usageAlert?.product === "MOBILE";
  const canNotifyAssets = canAddBar;
  const showAlert = usageAlert?.alertAction?.notifyAssetsBySMS === false && usageAlert?.alertAction?.contacts?.length === 0;

  const tooltipText = "An SMS will be sent to the mobile number the alert is created against when the alert level is breached.";
  const tooltipAutoBarText = `An autobar can be selected which will restrict the use of a mobile number. This can be used to assist in management of costs & reduce unexpected "bill shock".`;
  const tooltipContactsText =
    "Select one or more contacts who should be notified when the alert is breached. Contacts can be added from the Manage Contacts page.";
  const tooltipAutoBarWarrningText =
    "We will use reasonable endeavours to apply the autobar, as soon as we become aware that the mobile number has breached the usage alert amount. However, in certain circumstances that are beyond our control, such as delays in call data being received from the network, overall charges may exceed the set limit and are still liable to be paid for under your terms & conditions.";
  return (
    <div>
      <UIPaper className={classes.paper}>
        <UIGrid container direction="row" spacing={1}>
          {showAlert && (
            <UIAlert severity={"error"}>
              Please select 'Notify assets by SMS' or add at least one contact to receive notifications.
            </UIAlert>
          )}
          {canNotifyAssets && (
            <UIGrid item xs={12}>
              <div className={classes.tooltip}>
                <UITypography variant="h3" gutterBottom>
                  Notify the user of the mobile number?
                </UITypography>
                <UITooltip title={tooltipText} placement="right" arrow>
                  <InfoIcon color="secondary" data-cy="tooltip-action-notify-user" className={classes.infoIcon} />
                </UITooltip>
              </div>
              <UIFormControlLabel
                className={classes.checkbox}
                control={
                  <UICheckbox
                    name="notifyAssetsBySMS"
                    checked={Boolean(usageAlert?.alertAction?.notifyAssetsBySMS)}
                    onChange={onAlertActionChange}
                    color="primary"
                  />
                }
                label="Notify asset by SMS"
                labelPlacement="end"
              />
            </UIGrid>
          )}
          <UIGrid item xs={12}>
            <div className={classes.tooltip}>
              <UITypography variant="h3" gutterBottom>
                Notify contacts?
              </UITypography>
              <UITooltip title={tooltipContactsText} placement="right" arrow>
                <InfoIcon color="secondary" data-cy="tooltip-action-notify-contacts" className={classes.infoIcon} />
              </UITooltip>
            </div>
          </UIGrid>
          <UIGrid item xs={12} md={8}>
            <SelectDropdown
              name="contact"
              label="Select Contact"
              data={contactsParsed || []}
              infiniteScroll={true}
              multiple={false}
              nextPage={fetchNextPage}
              hasNextPage={hasNextPage}
              onChange={onContactChange}
            />
          </UIGrid>
        </UIGrid>
        <div data-cy="notify-contacts-table" className={classes.table}>
          <MUIDataTable
            title=""
            data={parsedTableContacts}
            columns={["Contact", "Email", "SMS", { label: "", name: "" }]}
            options={{
              textLabels: {
                body: {
                  noMatch: "No contacts added",
                },
              },
              download: false,
              elevation: 1,
              print: false,
              responsive: "standard",
              selectToolbarPlacement: "none",
              filter: false,
              serverSide: false,
              viewColumns: false,
              sort: true,
              rowsPerPageOptions: [10, 20, 50, 100],
              search: false,
              selectableRows: "none",
              rowHover: true,
              setRowProps: (row) => ({ "data-cy": `row-id-${row[0]}` }),
              setTableProps: () => ({ size: "small" }),
            }}
          />
        </div>
      </UIPaper>
      {canAddBar && (
        <UIPaper className={classes.paper}>
          <div className={classes.tooltip}>
            <UITypography variant="h3" gutterBottom>
              Add an autobar?
            </UITypography>
            <UITooltip title={tooltipAutoBarText} placement="right" arrow>
              <InfoIcon color="secondary" data-cy="tooltip-action-autobar" className={classes.infoIcon} />
            </UITooltip>
          </div>
          <SelectDropdown
            required={!bulkEdit}
            name="bar"
            label="Add Bar"
            value={usageAlert?.alertAction?.bar?.name || ""}
            data={barActions}
            onChange={onBarChange}
            ctrClass={classes.barDropdown}
          />
          <span>
            <UITooltip title={tooltipAutoBarWarrningText} placement="right">
              <WarningIcon color="primary" className={classes.icon} data-cy="tooltip-warning-autobar" />
            </UITooltip>
          </span>
        </UIPaper>
      )}
    </div>
  );
};

const useStyles = makeStyles((theme: UITheme) =>
  createStyles({
    table: {
      "& th:nth-child(2)": {
        paddingLeft: 22,
      },
      "& th:nth-child(3)": {
        paddingLeft: 22,
      },
    },
    checkbox: {
      margin: 0,
      marginBottom: theme.spacing(2),
    },
    paper: {
      padding: theme.spacing(3),
      marginBottom: theme.spacing(2),
    },
    addContact: {
      whiteSpace: "nowrap",
      marginBottom: theme.spacing(3),
      minWidth: 155,
    },
    barDropdown: {
      maxWidth: "270px",
    },
    tooltip: {
      display: "flex",
      gap: theme.spacing(1.5),
    },
    icon: {
      marginTop: theme.spacing(1),
      marginLeft: theme.spacing(2),
    },
    infoIcon: {
      cursor: "pointer",
    },
  }),
);
