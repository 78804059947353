import React, { useEffect } from "react";
import { Redirect, Route, RouteProps, useHistory } from "react-router-dom";
import { login } from "../sso-api";
import { useIdentityUser } from "../hooks";
import { useHubUserExist } from "../../user/hooks";
import { useMyAccounts } from "../../account/hooks";
import LoadingOverlay from "../../_app/components/LoadingOverlay";
import { getCachedContextHierarchy } from "../../context/utils";
import { isCognitoUser } from "../utils";
import { useAuthenticator } from "@aws-amplify/ui-react";

export const PrivateRoute = ({ children, ...rest }: RouteProps) => {
  const history = useHistory();
  const { authStatus } = useAuthenticator((context) => [context.authStatus]);

  const { data: identityUser, isFetching: identityUserFetching } = useIdentityUser();
  const hasValidIdentityUser = identityUser && !identityUser?.expired;

  const { data: doesHubUserExist, isFetching: hubUserExistenceFetching } = useHubUserExist({
    enabled: hasValidIdentityUser,
  });

  const { data: groups } = useMyAccounts();
  const hasGroupContext = Boolean(getCachedContextHierarchy()?.lastParentId);
  const hasMultipleGroups = groups && groups.length > 1;

  // Log in if we don't have a valid IdP user
  useEffect(() => {
    if (!identityUserFetching && !hasValidIdentityUser) {
      if (isCognitoUser()) {
        history.replace("/login");
      } else {
        login();
      }
    }
  }, [hasValidIdentityUser, identityUserFetching]);

  // Redirect to the `no-user` page if we don't have a user in Hub
  useEffect(() => {
    if (hasValidIdentityUser && !hubUserExistenceFetching && !doesHubUserExist) {
      setTimeout(() => history.replace("/no-user"));
    }
  }, [hasValidIdentityUser, hubUserExistenceFetching, doesHubUserExist, history]);

  // Redirect to the Group selection if a group was not selected yet
  useEffect(() => {
    if (!hasGroupContext && hasMultipleGroups) {
      setTimeout(() => history.replace("/groups"));
    }
  }, [hasMultipleGroups, hasGroupContext, history]);

  if (isCognitoUser() && authStatus !== "authenticated" && hasValidIdentityUser) {
    return <Redirect to="/login" />;
  }

  if (doesHubUserExist) {
    return <Route {...rest}>{children}</Route>;
  }

  return <LoadingOverlay />;
};
