import api from "../_app/api";
import { Contact, GetContactsResponse } from "./types";
import { contactTypesMap } from "./utils";

export const getContactById = async (id: string) => {
  const { data }: { data: Contact } = await api({
    method: "GET",
    url: "/contacts/" + id,
    context_injection: true,
  });
  return parseContact(data);
};

export const getContacts = async (page: number, limit: number) => {
  let { data }: { data: GetContactsResponse } = await api({
    method: "GET",
    url: "/contacts",
    context_injection: true,
    params: {
      page,
      "size": limit,
    },
  });
  data?.list?.map(parseContact);
  return data;
};

export const createContact = async (contact: Contact) => {
  const { data }: { data: Contact } = await api({
    method: "POST",
    url: "/contacts",
    context_injection: true,
    data: {
      ...contact,
    },
  });
  return parseContact(data);
};

export const updateContact = async (contact: Contact) => {
  const { data }: { data: Contact } = await api({
    method: "PUT",
    url: `/contacts/${contact?.id}`,
    context_injection: true,
    data: {
      ...contact,
      typeFriendly: undefined,
    },
  });
  return parseContact(data);
};

export const deleteContact = async (contact: Contact) => {
  const { data }: { data: Contact } = await api({
    method: "DELETE",
    url: `/contacts/${contact?.id}`,
    context_injection: true,
  });
  return parseContact(data);
};

export const parseContact = (contact: Contact) => {
  contact.typeFriendly = contactTypesMap[contact.type];
  return contact;
};
