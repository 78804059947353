import React, { ReactNode } from "react";
import { Alert, CssBaseline, Grid, TypographyProps } from "@mui/material";
import { Theme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import UILoader from "../components/UILoader";
import { useBrandName } from "../../brand/hooks";
import { useHasFeature } from "../../feature/hooks";
import { useHasPermission } from "../../permission/hooks";

interface SecondaryProps {
  children: ReactNode;
  title?: string;
  titleProps?: TypographyProps;
  isLoading?: boolean;
  accessPermission?: string | undefined;
  featureFlag?: string | undefined;
  className?: string;
}

function Secondary({
  title,
  titleProps,
  isLoading,
  accessPermission = "",
  featureFlag,
  children,
  className,
  ...rest
}: SecondaryProps) {
  const classes = useStyles();
  const brandName = useBrandName();
  const hasPermission = useHasPermission(accessPermission, { enabled: Boolean(accessPermission) });
  const hasFeatureFlag = useHasFeature(featureFlag);
  const hasFeature = featureFlag ? hasFeatureFlag : true;

  React.useEffect(() => {
    document.title = `${brandName} ${title ? "- " + title : ""}`;
  }, [brandName, title]);

  const renderContent = () => {
    if (hasPermission && hasFeature) {
      return children;
    }
    return (
      <Grid item className={classes.error}>
        <Alert severity="info">You do not have permission to view this page.</Alert>
      </Grid>
    );
  };

  return (
    <div className={classes.root} {...rest}>
      <CssBaseline />
      <div className={`${classes.main} ${className}`}>
        {isLoading ? (
          <div className={classes.loader}>
            <UILoader />
          </div>
        ) : (
          <>{renderContent()}</>
        )}
      </div>
    </div>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minHeight: "100vh",
      width: "100vw",
      backgroundColor: "#ffffff",
    },
    main: {
      minWidth: "100%",
      minHeight: "100vh",
    },
    error: {
      padding: theme.spacing(2),
    },
    loader: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100vh",
      flex: 1,
    },
  }),
);

export default Secondary;
