import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Theme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Main from "../../_app/layouts/Main";
import EditUserForm from "../components/EditUserForm";
import { useUserAccountAssign, useUserCreate } from "../hooks";
import { permissionCodes } from "../../permission/hooks";
import { User, UserAccountAssignRequest, UserId } from "../types";
import { simplifyPermissions } from "../../permission/utils";
import { getUserIdByEmail } from "../api";

export const AddUser = () => {
  const history = useHistory();
  const classes = useStyles();
  const [fetchingUserId, setFetchingUserId] = useState<boolean>(false);

  const {
    mutate: executeUserCreate,
    error: createError,
    isLoading: userCreateIsLoading,
  } = useUserCreate({
    onSuccess: () => {
      history.push("/users");
    },
  });

  const { mutate: executeUserAssign, isLoading: userAssignIsLoading } = useUserAccountAssign({
    onSuccess: () => {
      history.push("/users");
    },
  });

  const addUser = (user: User) => {
    const createUserPayload = {
      userDetails: {
        email: user.email,
        firstName: user.firstName,
        lastName: user.lastName,
      },
      accountIds: user.accounts?.map((account) => account.id) || [],
      roleId: user.roleId ? parseInt(user.roleId) : undefined,
      permissions: user.permissions ? simplifyPermissions(user.permissions) : [],
    };

    executeUserCreate(createUserPayload);
  };

  const assignUserToAccount = (userId: UserId, user: User) => {
    const userAssignPayload: UserAccountAssignRequest = {
      accountIds: user.accounts?.map((account) => account.id) || [],
      roleId: user.roleId ? parseInt(user.roleId) : undefined,
      permissions: user.permissions ? simplifyPermissions(user.permissions) : [],
    };

    executeUserAssign({ user: userAssignPayload, userId });
  };

  const onSubmit = (user: User) => {
    setFetchingUserId(true);
    getUserIdByEmail(user.email)
      .then((userId: UserId) => assignUserToAccount(userId, user))
      .catch(() => addUser(user))
      .finally(() => setFetchingUserId(false));
  };

  const user = createError?.config?.data ? { ...JSON.parse(createError.config.data), email: null } : {};

  const isLoading = userCreateIsLoading || userAssignIsLoading || fetchingUserId;

  return (
    <Main title="Add User" data-cy="add-user-page" accessPermission={permissionCodes.USERS_MANAGE} isLoading={isLoading}>
      <Card elevation={1} className={classes.card}>
        <Grid container>
          <EditUserForm
            user={user}
            newUser={true}
            hasEmailConfirmation
            isSubmitting={isLoading}
            handleSubmit={onSubmit}
            handleCancel={() => history.goBack()}
          />
        </Grid>
      </Card>
    </Main>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      padding: theme.spacing(5),
    },
  }),
);

export default AddUser;
