import { CONTEXT_HIERARCHY_KEY } from "../../context/utils";
import actions from "./Actions";
import { Action } from "./Store";

const Reducer = (state: any, action: Action) => {
  switch (action?.type) {
    case actions.SET_ERROR:
      return {
        ...state,
        success: "",
        alert: "",
        error: action?.payload,
      };
    case actions.SET_SUCCESS:
      return {
        ...state,
        error: "",
        alert: "",
        success: action?.payload,
      };
    case actions.SET_ALERT:
      return {
        ...state,
        success: "",
        error: "",
        alert: action?.payload,
      };
    case actions.SET_CONTEXT_HIERARCHY:
      const newContext = {
        ...(action?.payload || {}),
        lastParentId: action?.payload?.lastParentId || action?.payload?.id,
      };
      if (!action?.skipStorage) {
        if (action?.payload) {
          localStorage.setItem(CONTEXT_HIERARCHY_KEY, JSON?.stringify(newContext));
        } else localStorage.removeItem(CONTEXT_HIERARCHY_KEY);
      }
      return {
        ...state,
        contextHierarchy: newContext,
      };
    case actions.SET_BRAND:
      return {
        ...state,
        brand: action?.payload,
      };
    default:
      return state;
  }
};

export default Reducer;
