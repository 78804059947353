import { useHasFeature } from "../../feature/hooks";
import { featureFlagsMap } from "../../feature/utils";
import { useStore } from "../hooks";
import { loadLiveChat } from "../lib/livechat";

function LiveChatProvider() {
  const { state } = useStore();
  const hasFeature = useHasFeature(featureFlagsMap.LIVE_CHAT);
  if (hasFeature && !state.isNestedApp) loadLiveChat();
  return null;
}

export default LiveChatProvider;
