import { Authenticator } from "@aws-amplify/ui-react";
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useMyAccounts } from "../../account/hooks";
import { useQueryClient } from "react-query";
import { AuthUser } from "aws-amplify/auth";

const SignInRedirect = ({ user }: { user?: AuthUser }) => {
  const history = useHistory();
  const queryClient = useQueryClient();

  useMyAccounts();

  useEffect(() => {
    if (user) {
      queryClient.invalidateQueries(["hubUserExist"]);
      history.replace("/"); // Redirect to dashboard after sign-in
    }
  }, [user, history]); // Only run when `user` or `history` changes

  return null;
};

const Login = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "flex-start",
        height: "50vh",
        paddingTop: "15vh",
      }}
    >
      <Authenticator hideSignUp={true} loginMechanisms={["email"]}>
        {({ user }) => {
          return <SignInRedirect user={user} />;
        }}
      </Authenticator>
    </div>
  );
};

export default Login;
