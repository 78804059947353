import React from "react";
import { Grid, Typography } from "@mui/material";
import { Theme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import Secondary from "../../_app/layouts/Secondary";
import GroupCard from "../../account/components/GroupCard";
import { useMyAccounts } from "../../account/hooks";
import { useLogo } from "../../brand/hooks";
import { useUserLevelMap } from "../../user-level/hooks";

const Groups = () => {
  const classes = useStyles();
  const { data, isFetching } = useMyAccounts();
  const userLevels = useUserLevelMap();
  const logo = useLogo();
  const [groupLevelName, setGroupLevelName] = React.useState(userLevels?.[10]?.name?.toLowerCase());

  React.useEffect(() => {
    setGroupLevelName(userLevels?.[10]?.name?.toLowerCase());
  }, [userLevels]);

  return (
    <Secondary data-cy="groups-selector-page" title="Group Selector" isLoading={isFetching}>
      <div className={classes.title}>
        <div
          className={classes.logo}
          style={{
            background: `url('${logo}') center center / contain no-repeat`,
          }}
        />
        <Typography variant="subtitle1">You have access to multiple {groupLevelName}s.</Typography>
        <Typography variant="subtitle1">Please select the {groupLevelName} you want to access.</Typography>
      </div>
      <Grid container direction="row" className={classes.gridContainer} spacing={2}>
        {data?.map((group) => <GroupCard key={group.id} group={group} lg={data?.length >= 3 ? 4 : 12 / data?.length} />)}
      </Grid>
    </Secondary>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    gridContainer: {
      marginLeft: "auto",
      marginRight: "auto",
      maxWidth: "1128px",
      justifyContent: "center",
      paddingBottom: theme.spacing(3),
      [theme.breakpoints.down("lg")]: {
        maxWidth: "752px",
      },
      [theme.breakpoints.down("md")]: {
        maxWidth: "377px",
      },
    },
    title: {
      textAlign: "center",
      paddingBottom: theme.spacing(4),
      paddingTop: theme.spacing(4),
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
    },
    logo: {
      height: "150px",
      width: "290px",
      margin: "0px 10px 30px 10px",
    },
  }),
);

export default Groups;
