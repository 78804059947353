import { useAuthenticator } from "@aws-amplify/ui-react";
import { Redirect } from "react-router-dom";
import React from "react";

export const CognitoLogout = () => {
  const { signOut } = useAuthenticator((context) => [context.user]);

  signOut();
  return <Redirect to="/login" />;
};
