import { IdValueQuery } from "../_app/api";

export interface SimDetails {
  simNumber: string;
  simType: string;
  simStatus: "ACTIVE" | "INACTIVE";
  pukCode?: string;
}

export type SimParams = {
  simPrefix: string;
  simLength: number;
};

export enum SimType {
  Triple = "TRIPLE",
  Esim = "ESIM",
}

export type RequestStatus = "PENDING" | "FAILED" | "COMPLETE" | null;

export interface RequestAutoSwitchParams {
  assetIds?: string[];
  filters?: IdValueQuery[];
  operations?: {
    requestType: "PAC" | "STAC" | "ETF" | string;
    sendSms: boolean;
  }[];
}

export interface AutoSwitchResult {
  autoSwitchType: "PAC" | "STAC" | "ETF";
  status: RequestStatus;
  reason: string;
  cli: string;
  code: string | undefined | null;
  expiryDate: string;
  earlyTermination: number | null;
  sendSms: boolean;
}

export interface GetAutoSwitchResultsResp {
  list: AutoSwitchResult[];
  page: number;
  pageSize: number;
  total: number;
}

export interface DispatchedAsset {
  id: string;
  cli: string;
  assetType: string;
  account: string;
  active: boolean;
  tag: string;
  network: string;
  simNumber: string;
  newSimNumber: string;
  simType: string;
  newSimType: string;
  requestStatus: RequestStatus;
  simParams: {
    simLength: number;
    simPrefix: string;
  };
}

export interface DisconnectedAsset {
  id: string;
  cli: string;
  requestStatus: RequestStatus;
  account: string;
  autoSwitch: boolean;
  active: boolean;
  expiryDate: string;
  dws: boolean;
  assetType: "MOBILE";
  tag: string;
  network: string;
  simNumber: string;
  pacCode: string;
  portingDate: string;
  stacCode: string;
  fee: number;
  simParams: {
    simLength: number;
    simPrefix: string;
  };
}

export interface GetDispatchedAssetsResponse {
  page: number;
  pageSize: number;
  total: number;
  list: DispatchedAsset[];
}

export interface GetDisconnectionAssetsResponse {
  page: number;
  pageSize: number;
  total: number;
  list: DisconnectedAsset[];
}

export interface SimSwapOperation {
  assetId: string;
  simNumber: string;
  simType: SimType;
}
