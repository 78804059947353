import React, { useState } from "react";
import { Theme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import { AttributeType } from "@aws-sdk/client-cognito-identity-provider/dist-types/models/models_0";
import { useCognitoUserAttributes, useCognitoUserUpdate } from "../hooks";
import Main from "../../_app/layouts/Main";
import { useValidation } from "../../form/hooks";
import { UIButton, UICard, UIGrid, UITextField } from "../../_app/components";
import MFASection from "../components/MFASection";

export const MyDetails = () => {
  const classes = useStyles();
  const [attributes, setAttributes] = useState<{ [key: string]: string }>({});

  const { data, isFetching } = useCognitoUserAttributes({});
  const { mutate: executeUserUpdate, isLoading: isUpdating } = useCognitoUserUpdate();

  const handleInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setAttributes((prevAttributes) => ({
      ...prevAttributes,
      [name]: value,
    }));
  };

  const handleUpdate = () => {
    const errors = validateAll();

    if (errors.length == 0) {
      const userAttributes: AttributeType[] = Object.entries(attributes).map(([Name, Value]) => ({
        Name,
        Value,
      }));

      executeUserUpdate(userAttributes);
    }
  };

  const { validateAll } = useValidation({}, { ...attributes });

  return (
    <Main title="My Details" data-cy="my-details-page" isLoading={isFetching || isUpdating}>
      <UIGrid item xs={12} md={6} data-cy="my-details-form">
        <UICard elevation={1} isSimpleCard className={classes.card}>
          <UITextField
            key={data?.email}
            disabled
            className={classes.textField}
            name="email"
            label="Email address/Username"
            type="email"
            defaultValue={data?.email}
            variant="outlined"
            size="small"
            fullWidth
            onChange={handleInput}
          />
          <UITextField
            key={data?.firstName}
            className={classes.textField}
            name="given_name"
            label="First Name"
            type="text"
            defaultValue={data?.firstName}
            variant="outlined"
            size="small"
            fullWidth
            onChange={handleInput}
          />
          <UITextField
            key={data?.lastName}
            className={classes.textField}
            name="family_name"
            label="Last Name"
            type="text"
            defaultValue={data?.lastName}
            variant="outlined"
            size="small"
            fullWidth
            onChange={handleInput}
          />
          <UIButton variant="contained" color="primary" onClick={handleUpdate} data-cy="update-button">
            Update
          </UIButton>
        </UICard>
      </UIGrid>
      <UIGrid item xs={12} md={6} data-cy="mfa-section">
        <UICard elevation={1} className={classes.card}>
          <MFASection />
        </UICard>
      </UIGrid>
    </Main>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      padding: theme.spacing(4),
    },
    mfaSwitch: {
      gap: 1,
    },
    loader: {
      marginLeft: theme.spacing(1),
    },
    qrCode: {
      width: "100%",
      display: "flex",
      justifyContent: "center",
    },
    verifyField: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      gap: 3,
    },
    textField: {
      marginBottom: theme.spacing(4),
    },
  }),
);

export default MyDetails;
