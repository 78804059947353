import { DispatchedAsset, SimParams, SimType } from "./types";

const simParamsByNetwork: Record<string, SimParams> = {
  VODAFONE: { simPrefix: "894410", simLength: 20 },
  O2: { simPrefix: "894411", simLength: 19 },
};

export function getSimTypes() {
  return [
    { value: SimType.Triple, label: "Triple (Standard, Micro & Nano)" },
    { value: SimType.Esim, label: "E-Sim" },
  ];
}

export function getSimParams(network: string): SimParams {
  return simParamsByNetwork[network];
}

export function canActivateSim(sim: DispatchedAsset) {
  const status = sim.requestStatus;
  return !(status === "PENDING" || status === "COMPLETE");
}
