import React, { useState } from "react";
import { useAssetUsageAlerts } from "../../alerts/hooks";
import UsageAlertTable from "../../alerts/components/UsageAlerts/Table/UsageAlertTable";
import { IdValueQuery } from "../../_app/api";

interface AssetUsageAlertsProps {
  assetId: string;
}

const queries: IdValueQuery[] = [];

export const AssetUsageAlerts = ({ assetId }: AssetUsageAlertsProps) => {
  const [rowCount, setRowCount] = useState(25);

  const { data: alerts, isFetching, hasNextPage, fetchNextPage } = useAssetUsageAlerts(assetId, rowCount, queries, {});

  return (
    <UsageAlertTable
      result={alerts}
      isFetching={isFetching}
      hasNextPage={Boolean(hasNextPage)}
      fetchNextPage={fetchNextPage}
      rowCount={rowCount}
      onRowCountChange={setRowCount}
    />
  );
};

export default AssetUsageAlerts;
