import { User } from "oidc-client-ts";

export type IdentityUser = User;

export interface DecodedJWT {
  sub: string;
  client_id: string;
  tenant_ref: string;
  given_name?: string;
  family_name?: string;
  auth_time?: number;
  exp?: number;
  iss?: string;
  brand_ref?: string;
  scope?: string[];
}

export interface AuthConfig {
  tokenIssuerUrl: string;
  hostedLoginPageDomain: string;
  customerPortalClientId: string;
}

export const SUCCESS = "SUCCESS";
export const ERROR = "ERROR";
export type VerifyTotpResponse = typeof SUCCESS | typeof ERROR;

export interface SignedInUser {
  id_token?: string;
  expired?: boolean;
  session_state?: string | null;
  access_token?: string;
  token_type?: string;
  scope?: string;
  profile: UserProfile;
  expires_at?: number;
  state?: unknown;
}

export interface UserProfile {
  sub?: string;

  [key: string]: any;
}